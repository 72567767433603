import React from "react";
import { Link } from "react-router-dom";

const HeaderComp = ({ title, total, to }) => {
  return (
    <Link to={to} className="flex flex-col">
      <h1 className="text-xs md:text-sm font-semibold text-gray-400">
        {title}
      </h1>
      <h3 className="text-2xl md:text-4xl font-semibold text-black">{total}</h3>
    </Link>
  );
};

export default HeaderComp;
