import { useCallback, useEffect, useMemo, useState } from "react";
import { FaRegCalendar } from "react-icons/fa";
import moment from "moment";
import { v2Api } from "../../../../api/v2.api";
import { BarChart2 } from "../../../charts";

const UserDateAnalyticsChart = () => {
  const [pageData, setPageData] = useState({
    data: null,
    from: moment().subtract(6, "days").startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
    label: "Last 7 Days (Today included)",
    isLoading: false,
  });

  const handleDateSelect = useCallback(() => {
    window.setDateRange({
      type: "orderAnalyticsCardDateRange",
      callBack: (r) => {
        setPageData((p) => ({
          ...p,
          from: r.from,
          to: r.to,
          label: r.label,
        }));
        // console.log(r);
      },
    });
  }, []);

  const onInitDataFetch = useCallback(async () => {
    setPageData((s) => ({ ...s, isLoading: true }));

    const { isSuccess, data, message } = await v2Api({
      tags: ["user"],
      operation: "getDateWiseUserAnalytics",
      dateRange: {
        from: pageData.from,
        to: pageData.to,
      },
    });

    if (isSuccess) {
      setPageData((s) => ({ ...s, data }));
      window.toast(message, "success");
    } else {
      window.toast(message, "error");
    }

    setPageData((s) => ({ ...s, isLoading: false }));
  }, [pageData.from, pageData.to]);

  const formatedData = useMemo(() => {
    const chartData = {
      labels: pageData.data?.map((obj) =>
        moment(obj?.date).format("DD MMM YY")
      ),
      datasets: [
        {
          label: "Total",
          data: pageData.data?.map((obj) => obj?.total),
          backgroundColor: "#2136d4",
          borderRadius: 10,
        },
      ],
    };
    return chartData;
  }, [pageData.data]);

  useEffect(() => {
    onInitDataFetch();
  }, [onInitDataFetch, pageData.from, pageData.to]);

  return (
    <div className="flex flex-col bg-white rounded-lg border border-gray-200">
      <div className=" flex w-full justify-between  flex-wrap whitespace-nowrap border-b p-4">
        <span className="text-xl font-semibold capitalize">
          date wise user analytics
        </span>
        <span
          onClick={handleDateSelect}
          className=" border cursor-pointer shadow-md rounded-xl flex gap-2 items-center text-[.825rem] px-2 py-1 "
        >
          {pageData?.label} <FaRegCalendar />
        </span>
      </div>

      <div className="size-auto px-4 pb-4">
        {pageData.isLoading ? (
          <div className="skeleton h-[15rem] mt-4 rounded-lg"></div>
        ) : (
          pageData.data && <BarChart2 data={formatedData} title={""} />
        )}
      </div>
    </div>
  );
};

export default UserDateAnalyticsChart;
