import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaAngleLeft } from "react-icons/fa";
import TextSearchBar from "./subComponents/TextSearchBar";
import ServiceAvailable from "./subComponents/ServiceAvailable";
import DefaultLocationSuggestions from "./subComponents/DefaultLocationSuggestions";
import Map from "./subComponents/Map";
import WarningModalv2 from "../../../../../../assets/WarningModalv2";
import StoreContext from "../../../../../../context/StoreContext";
import { RxCross2 } from "react-icons/rx";
import {
  decimalToDMS,
  dmsToDecimal,
} from "../../../../../../functions/helperFunctions/helperFunctions";

let queryDebounceTime = 1000;
let autocompleteService;
let country = "IN";
let timeoutId;
let autoCompleteRef = null;
let mapContainerRef = null;
let minSearchTextLengthToStartSearch = 4;

/*
  const [locationData, setLocationData] = useState({
    isLocationAddModalRender: false,
  });

  <AddLocationsIdx locationData={locationData} setLocationData={setLocationData} />

*/

export default function AddLocationsIdx({
  locationData,
  setLocationData,
  callBack,
}) {
  return (
    <AnimatePresence>
      {locationData.isLocationAddModalRender && (
        <Modal
          setLocationData={setLocationData}
          locationData={locationData}
          callBack={callBack}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ setLocationData, callBack, locationData }) => {
  const [states, setStates] = useState({
    lat: locationData.selectedLocation?.lat || 20,
    lng: locationData.selectedLocation?.lng || 82,
    map: null,
    marker: null,
    address: "",
    placeId: "",
    predictions: [],
    query: "",
    defaultUi: true,
    isChangeMapPosition: false,
    mapScriptLoaded: false,
    searchBarResultAddress: "",
    loadingCurrentLocation: false,
    isPredictionsShow: false,
    loadingPredictions: false,
    loadingPointLocation: false,
    showServiceCites: true,
    loadingReverseGeoCode: false,
    showMap: false,
    serviceAvailable: false,
    noServiceAvailable: false,
    loadingLocation: false,
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
    searchUsingCoordinates: false,
    newLat: "",
    newLng: "",
    combinedDMS: "",
  });

  const { storeStates, setStoreStates } = useContext(StoreContext);

  useEffect(() => {
    // Check if the script is already loaded
    if (!storeStates.isGoogleMapsScriptLoaded) {
      // Load the Google Maps API script
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
      script.defer = true;
      document.head.appendChild(script);

      // Set the global variable to true once the script is loaded
      script.onload = () => {
        setStoreStates((p) => ({
          ...p,
          isGoogleMapsScriptLoaded: true,
        }));
        initMap();
      };

      // Cleanup to prevent memory leaks
      return () => {
        document.head.removeChild(script);
      };
    } else {
      // The script is already loaded, perform any necessary actions
      initMap();
    }

    // eslint-disable-next-line
  }, []);

  const initMap = () => {
    setStates((p) => ({ ...p, mapScriptLoaded: true }));
    // initializing the autocomplete service for getting predictions
    autocompleteService = new window.google.maps.places.AutocompleteService();
  };

  const handleClose = () => {
    if (!states.defaultUi) {
      setStates((p) => ({
        ...p,
        defaultUi: true,
        loadingCurrentLocation: false,
        isPredictionsShow: false,
        loadingPredictions: false,
        loadingPointLocation: false,
        showServiceCites: true,
        loadingReverseGeoCode: false,
        serviceAvailable: false,
        noServiceAvailable: false,
        loadingLocation: false,
      }));
    } else {
      setLocationData((p) => ({
        ...p,
        isLocationAddModalRender: false,
      }));
    }
  };

  const handleConfirmLocation = () => {
    try {
      const addressLine1 =
        states.searchBarResultAddress?.split(",")[0] ||
        states.premise ||
        states.streetName ||
        states.route ||
        states.s_locality1 ||
        states.s_locality2 ||
        states.s_locality3 ||
        states.city ||
        states.pincode;

      const selectedLocation = {
        lat: states.lat,
        lng: states.lng,
        addressLine1,
        city: states.city,
        state: states.state,
        pincode: states.pincode,
        addressLine2: states.searchBarResultAddress || states.formatedAddress,
      };
      callBack(selectedLocation);
      setLocationData((p) => ({
        ...p,
        isLocationAddModalRender: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDone = () => {
    setStates((p) => ({
      ...p,
      lat: parseFloat(states.newLat),
      lng: parseFloat(states.newLng),
      isChangeMapPosition: true,
      showMap: true,
      defaultUi: false,
    }));
  };

  const handleLatChange = (e) => {
    const newLat = e.target.value;
    let newCombinedDMS = "";

    try {
      const latDecimal = dmsToDecimal(newLat);
      const lngDecimal = dmsToDecimal(states.newLng);

      newCombinedDMS = `${decimalToDMS(latDecimal, true)} + ${decimalToDMS(
        lngDecimal,
        false
      )}`;
    } catch {
      newCombinedDMS = "";
    }

    setStates((p) => ({
      ...p,
      newLat,
      combinedDMS: newCombinedDMS,
    }));
  };

  const handleLngChange = (e) => {
    const newLng = e.target.value;
    let newCombinedDMS = "";

    try {
      const latDecimal = dmsToDecimal(states.newLat);
      const lngDecimal = dmsToDecimal(newLng);

      newCombinedDMS = `${decimalToDMS(latDecimal, true)} + ${decimalToDMS(
        lngDecimal,
        false
      )}`;
    } catch {
      newCombinedDMS = "";
    }

    setStates((p) => ({
      ...p,
      newLng,
      combinedDMS: newCombinedDMS,
    }));
  };

  return (
    <div className="fixed right-0 top-0 left-0 bottom-0 z-[999]">
      <div
        onClick={() => {
          setLocationData((p) => ({
            ...p,
            isLocationAddModalRender: false,
          }));
        }}
        className="fixed right-0 top-0 left-0 bottom-0 z-[-1] bg-[#00000026]"
      ></div>
      <motion.div
        initial={{ translateY: "100%" }}
        animate={{ translateY: 0 }}
        exit={{ translateY: "100%" }}
        transition={{ duration: 0.3, ease: "linear" }}
        className="w-full text-[.875rem] flex flex-col items-center border border-gray-200 max-w-[30rem] bg-[white] fixed top-0 left-0 bottom-0 z-[2] "
      >
        <div className="flex w-full gap-2 items-center bg-[white] p-4 pb-2 pt-3 text-[1.125rem] font-[500] ">
          <FaAngleLeft onClick={handleClose} className="text-[1.25rem]" />
          <div>Select service location</div>
        </div>

        <div className="w-full mb-2  pb-2 bg-white">
          {states.searchUsingCoordinates ? (
            <>
              <div className="flex  gap-4 w-full px-3 items-center">
                <input
                  type="text"
                  name="lat"
                  value={states.newLat}
                  onChange={handleLatChange}
                  placeholder="Lat or DMS (e.g., 24°46'23.8\N)"
                  className="border w-full rounded-[.5rem] text-[1rem]  px-2 py-1 "
                />
                <input
                  type="text"
                  name="lng"
                  value={states.newLng}
                  onChange={handleLngChange}
                  placeholder="Long or DMS (e.g., 85°00'23.5\E)"
                  className="border w-full rounded-[.5rem] text-[1rem]  px-2 py-1"
                />
              </div>

              <div className=" flex justify-end mt-2  gap-4 w-full px-3 items-center">
                <button
                  onClick={handleDone}
                  className="blue-gradient rounded-[.5rem] px-3 py-2 flex items-center justify-center leading-[1]"
                >
                  Find location
                </button>
                <RxCross2
                  className="text-[red] text-[1.75rem]"
                  onClick={() =>
                    setStates((p) => ({
                      ...p,
                      searchUsingCoordinates: false,
                    }))
                  }
                />
              </div>
            </>
          ) : (
            <div
              onClick={() =>
                setStates((p) => ({
                  ...p,
                  searchUsingCoordinates: !p.searchUsingCoordinates,
                }))
              }
              className=" px-4 text-[1rem] text-[gray] w-full flex justify-end  gap-2"
            >
              Select using coordinates
              <input
                type="checkbox"
                checked={states.searchUsingCoordinates}
                onChange={(e) =>
                  setStates((p) => ({
                    ...p,
                    searchUsingCoordinates: e.target.checked,
                  }))
                }
              />
            </div>
          )}
        </div>
        {/* location search bar (position: absolute)  */}
        <TextSearchBar
          minSearchTextLengthToStartSearch={minSearchTextLengthToStartSearch}
          states={states}
          setStates={setStates}
          timeoutId={timeoutId}
          queryDebounceTime={queryDebounceTime}
          autocompleteService={autocompleteService}
          country={country}
        />

        <WarningModalv2 parentStates={states} setParentStates={setStates} />

        {/* the default initial ui (including the prediction search list) */}
        <DefaultLocationSuggestions states={states} setStates={setStates} />

        {/* if the selected location on the map is serviceable */}
        <ServiceAvailable
          states={states}
          handleConfirmLocation={handleConfirmLocation}
          setStates={setStates}
          autoCompleteRef={autoCompleteRef}
        />

        {/* the main google map */}
        {states.showMap && (
          <Map
            states={states}
            setStates={setStates}
            mapContainerRef={mapContainerRef}
          />
        )}
      </motion.div>
    </div>
  );
};
