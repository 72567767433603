import React from "react";
import ChartsIndex from "../../components/analytics/charts/ChartsIndex";
import AdminLayoutNavbar from "../../components/navbar/Navbar";
import BottomNav from "../../Layout/BottomNav";

export default function ChartsPage() {
  return (
    <div className="home-page fixed top-0 right-0 left-0 bottom-0 py-[6rem] px-4 overflow-scroll bg-blue-50 flex flex-wrap gap-2">
      <AdminLayoutNavbar />
      <ChartsIndex />
      <BottomNav />
    </div>
  );
}
