import { useCallback } from "react";
import { useStoreContext } from "../../../../../../context/StoreContext";
import { Link } from "react-router-dom";
import { FaRegCalendar } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { serviceableCities } from "../../../../../../utils/adminUtils";
import { BodyComp, HeaderComp } from "./OrderCounts_subC";
import useOrderCountData from "../../../../../../hooks/order/useOrderCountData";
import moment from "moment";

export default function OrderCounts({ returnPendingOrders = 0, className }) {
  const { storeStates, setStoreStates } = useStoreContext();

  const {
    data,
    isLoading,
    city,
    hub,
    dateRange,
    refetch,
    setCity,
    setHub,
    setDateRange,
    setGroupingFieldName,
    groupingFieldName,
  } = useOrderCountData({
    dateRange: {
      label: "Today",
      from: moment().startOf("day").toDate(),
      to: moment().endOf("day").toDate(),
    },
    countTypes: [
      "totalScheduledOrders",
      "totalPunchedOrders",
      "completedOrdersSitDr",
      "outForServiceOrdersSitDr",
      "todayDelayedOrders",
      "remainingOrdersSitDr",
      "sameDayService",
      "assignedWithSanta",
    ],
    groupingFieldName: "city",
  });

  const updateUniversalFilter = useCallback(() => {
    if (city !== "others") {
      setStoreStates((p) => ({
        ...p,
        universalFilter: {
          city,
          hub,
        },
      }));
    }
  }, [city, hub, setStoreStates]);

  const navigationUrl = useCallback(
    (filterationRules, label, isShiftWiseFilteration = true) => {
      return `/order/order-list/custom?options=${encodeURIComponent(
        JSON.stringify({
          filterationRules,
          dateFrom: dateRange.from,
          dateTo: dateRange.to,
          dateRangeLabel: dateRange.label,
          label,
          city,
          isShiftWiseFilteration,
        })
      )}`;
    },
    [city, dateRange.from, dateRange.label, dateRange.to]
  );

  return (
    <div
      className={`flex w-full flex-col border relative border-gray-200  bg-white rounded-lg   py-4 ${className}`}
    >
      <div className="w-full flex flex-wrap gap-2 justify-between items-center border-b px-4 pb-2 font-[600] mb-4 ">
        <div className="flex flex-col">
          <h1 className=" font-[500]  ">Order Analytics</h1>
          <h2 className="text-2xs md:text-xs capitalize">
            {city !== "all" ? city : ""}
            {hub !== "all" ? ` - ${hub}` : ""}
          </h2>
          <ul className="flex gap-2 flex-wrap mt-2">
            {["all", ...serviceableCities, "others"].map((label) => (
              <li
                className={`capitalize text-nowrap text-2xs rounded-full px-3 py-1 cursor-pointer ${
                  label === city
                    ? "blue-gradient text-white"
                    : "bg-gray-200 border-blue-200"
                }`}
                onClick={() => {
                  if (city !== label) {
                    setCity(label);
                    setHub("all");
                    setGroupingFieldName(label === "all" ? "city" : "name");
                  }
                }}
              >
                {label}
              </li>
            ))}
          </ul>
        </div>
        <div className="gap-4 flex items-center">
          {isLoading ? (
            <div className="loader w-[1rem]"></div>
          ) : (
            <IoReload
              onClick={refetch}
              className=" cursor-pointer text-[1.125rem] "
            />
          )}

          <div
            onClick={() => {
              window.setDateRange({
                type: "userAnalyticsCardDateRange",
                callBack: setDateRange,
              });
            }}
            className=" border cursor-pointer bg-white shadow-md rounded-md flex gap-2 items-center text-[.825rem] px-2 py-1 "
          >
            {dateRange.label} <FaRegCalendar />
          </div>
        </div>
      </div>

      <div className="px-4 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-0">
        <OrderCountsSubCard
          className={"md:pr-3 md:border-r"}
          HeaderComp={() => (
            <HeaderComp
              title="Punched Orders"
              total={data?.totalPunchedOrders?.count}
              to={navigationUrl(
                data?.totalPunchedOrders?.filterationRules,
                "Punched Orders"
              )}
            />
          )}
          SubHeaderComp={() => {
            return (
              <div className="flex gap-2 flex-wrap">
                {[
                  {
                    title: "Same day service",
                    total: data?.sameDayService?.count,
                    to: navigationUrl(
                      data?.sameDayService?.filterationRules,
                      "Same day service"
                    ),
                  },
                  {
                    title: "Assigned with santa",
                    total: data?.assignedWithSanta?.count,
                    to: navigationUrl(
                      data?.assignedWithSanta?.filterationRules,
                      "Assigned with Santa"
                    ),
                  },
                ]?.map((curr, index) => (
                  <PunchedOrdersSubHeaderComp
                    key={index}
                    item={curr}
                    className={curr?.total === 0 && "hidden"}
                  />
                ))}
              </div>
            );
          }}
          BodyItems={data?.totalPunchedOrders?.orders?.map((curr) => ({
            ...curr,
            to: `/order/order-list/custom?options=${encodeURIComponent(
              JSON.stringify({
                filterationRules: data?.totalPunchedOrders?.filterationRules,
                label: "Punched Orders",
                dateFrom: dateRange.from,
                dateTo: dateRange.to,
                dateRangeLabel: dateRange.label,
                city:
                  groupingFieldName === "city"
                    ? curr?.title
                    : storeStates?.hubs?.find(
                        (hub) => hub?.name === curr?.title
                      )?.city,
              })
            )}`,
          }))}
          BodyComp={BodyComp}
        />
        <OrderCountsSubCard
          className={"md:ps-3 md:border-s"}
          HeaderComp={() => (
            <HeaderComp
              title="Scheduled Orders"
              total={data?.totalScheduledOrders?.count}
              to={navigationUrl(
                data?.totalScheduledOrders?.filterationRules,
                "Scheduled Orders",
                true
              )}
            />
          )}
          SubHeaderComp={() => {
            return (
              <div
                className={`w-full items-center flex text-[1.125rem] justify-between border border-gray-300 p-2 shadow-sm rounded-md overflow-x-scroll ${
                  data?.totalScheduledOrders?.count === 0 && "hidden"
                }`}
              >
                {[
                  {
                    label: "Completed",
                    value: data?.completedOrdersSitDr?.count,
                    to: navigationUrl(
                      data?.completedOrdersSitDr?.filterationRules,
                      "Completed Orders"
                    ),
                  },
                  {
                    label: "Ongoing",
                    value: data?.outForServiceOrdersSitDr?.count,
                    to: navigationUrl(
                      data?.outForServiceOrdersSitDr?.filterationRules,
                      "Ongoing"
                    ),
                  },
                  {
                    label: "Remaining",
                    value: data?.remainingOrdersSitDr?.count,
                    to: navigationUrl(
                      data?.remainingOrdersSitDr?.filterationRules,
                      "Remaining scheduled Orders"
                    ),
                  },
                  {
                    label: "Scheduled",
                    value: data?.totalScheduledOrders?.count,
                    to: navigationUrl(
                      data?.totalScheduledOrders?.filterationRules,
                      "Scheduled Orders"
                    ),
                  },
                ]?.map((curr, index) => (
                  <>
                    <Link
                      to={curr.to}
                      onClick={updateUniversalFilter}
                      className="flex flex-col items-left cursor-pointer justify-center "
                    >
                      <div className="text-sm md:text-lg font-medium">
                        {curr.value}
                      </div>
                      <div className="w-full text-[7px] md:text-2xs font-medium text-gray-400">
                        {curr.label}
                      </div>
                    </Link>
                    {index < 3 && (
                      <div className="mx-0.5 md:mx-1">
                        {index < 2 ? "+" : "="}
                      </div>
                    )}
                  </>
                ))}
              </div>
            );
          }}
          BodyItems={data?.totalScheduledOrders?.orders?.map((curr) => ({
            ...curr,
            to: `/order/order-list/custom?options=${encodeURIComponent(
              JSON.stringify({
                filterationRules: data?.totalScheduledOrders?.filterationRules,
                label: "Scheduled Orders",
                dateFrom: dateRange.from,
                dateTo: dateRange.to,
                dateRangeLabel: dateRange.label,
                city:
                  groupingFieldName === "city"
                    ? curr?.title
                    : storeStates?.hubs?.find(
                        (hub) => hub?.name === curr?.title
                      )?.city,
              })
            )}`,
          }))}
          BodyComp={BodyComp}
        />
      </div>
    </div>
  );
}

const OrderCountsSubCard = ({
  className,
  HeaderComp,
  SubHeaderComp,
  BodyComp,
  BodyItems,
}) => {
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <HeaderComp />
      <SubHeaderComp />
      {!!BodyItems?.length && (
        <div className="flex flex-col border border-gray-300 max-h-[7.7rem] rounded-md overflow-y-scroll">
          {BodyItems.map((curr, index) => (
            <BodyComp index={index} item={curr} />
          ))}
        </div>
      )}
    </div>
  );
};

const PunchedOrdersSubHeaderComp = ({
  item,
  index,
  isReloading,
  className,
}) => {
  return (
    <Link
      key={index}
      to={item?.to}
      className={`flex justify-between gap-4 p-2 border border-gray-300 rounded-lg cursor-pointer ${className} `}
    >
      <div className="flex flex-col">
        <h1 className="text-black text-sm md:text-lg font-medium">
          {isReloading ? (
            <div className="h-6 w-12 md:h-7">
              <div className="skeleton size-full rounded-full"></div>
            </div>
          ) : (
            <span>{item?.total || 0}</span>
          )}
        </h1>
        <p className="whitespace-nowrap text-gray-400 font-medium text-[7px] md:text-2xs">
          {item?.title || "description"}
        </p>
      </div>
    </Link>
  );
};
