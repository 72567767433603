/*
use case:

   let scrollTimeout;    // at the top of the file ( outside the component)

    attributes for the scrollable container:
            ref={containerId1Ref}
            id={CARDS_LIST_CONTAINER_ID}
            onScroll={handleScroll}

    const handleScroll = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);

    scrollTimeout = setTimeout(() => {
      console.log("Scrolling");
      window.saveScrollPosition(containerId1Ref, CARDS_LIST_CONTAINER_ID, options); // Save the scroll position
    }, 300); // Save scroll state after 300ms of no scrolling
    };


    window.restoreScrollPosition(
        containerId1Ref,
        CARDS_LIST_CONTAINER_ID,
        ()=> {} // do something with options
    ); // Restore the scroll position

*/

/**
 * Saves the scroll position of a given container.
 * @param {Object} containerRef - React ref to the scrollable container.
 * @param {String} containerID - Unique ID for the container.
 */
const saveScrollPosition = (containerRef, containerID, options, setStates) => {
  try {
    if (!containerRef || !containerRef.current) {
      console.error("Invalid containerRef passed to saveScrollPosition.");

      return;
    }

    const x = containerRef.current.scrollLeft;
    const y = containerRef.current.scrollTop;

    setStates((prev) => ({
      ...prev,
      scrollHistory: {
        ...prev.scrollHistory,
        [containerID]: { x, y, options },
      },
    }));
  } catch (error) {
    console.error("Error saving scroll position:", error);
    window.toast("An error occurred while saving scroll position.", "error");
  }
};

/**
 * Restores the scroll position of a given container.
 * @param {Object} containerRef - React ref to the scrollable container.
 * @param {String} containerID - Unique ID for the container.
 * @param {Function} doWithOptions - Function to be called with saved options before restoring the scroll position.
 * @param {Object} scrollHistory - Object storing the scroll positions.
 * @param {Function} setStates - Function to update the state.
 */
const restoreScrollPosition = async (
  containerRef,
  containerID,
  doWithOptions,
  scrollHistory,
  setStates,
  onProcessDone
) => {
  try {
    if (!containerRef || !containerRef.current) {
      console.error("Invalid containerRef passed to restoreScrollPosition.");
      onProcessDone?.({
        message: "Invalid containerRef",
        processStatus: false,
      });
      return;
    }

    const scrollData = scrollHistory[containerID];

    if (!scrollData || (scrollData.x === 0 && scrollData.y === 0)) {
      onProcessDone?.({
        message: "No scroll data found",
        processStatus: false,
      });
      return; // Don't restore if x or y is 0, or if scrollData is undefined
    }

    if (doWithOptions) {
      await doWithOptions(scrollData.options);
    }
    containerRef.current.scroll({
      left: scrollData.x,
      top: scrollData.y,
      behavior: "smooth", // Enables smooth scrolling
    });

    setStates((prev) => ({
      ...prev,
      scrollHistory: {
        ...prev.scrollHistory,
        [containerID]: null,
      },
    }));

    onProcessDone?.({
      message: "Scroll position restored",
      processStatus: true,
    });
  } catch (error) {
    onProcessDone?.({
      message: error?.message,
      processStatus: false,
    });
    console.error("Error restoring scroll position:", error);
    window.toast("An error occurred while restoring scroll position.", "error");
  }
};

export { saveScrollPosition, restoreScrollPosition };
