import { useCallback, useEffect, useState } from "react";
import { v2Api } from "../../api/v2.api";
import moment from "moment";
import { useStoreContext } from "../../context/StoreContext";

const useOrderCountData = ({
  dateRange: _dateRange,
  countTypes: _countTypes,
  groupingFieldName: _groupingFieldName, // name means hubName or city
}) => {
  const {
    storeStates: { universalFilter, hubsByCity },
  } = useStoreContext();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [countTypes] = useState(_countTypes);

  const [dateRange, setDateRange] = useState({
    label: _dateRange?.label || "Today",
    from: _dateRange?.from || moment().startOf("day").toDate(),
    to: _dateRange?.to || moment().endOf("day").toDate(),
  });
  const [city, setCity] = useState(universalFilter.city);
  const [hub, setHub] = useState(universalFilter.hub);
  const [groupingFieldName, setGroupingFieldName] =
    useState(_groupingFieldName);

  const onInitailLoad = useCallback(async () => {
    setIsLoading(true);

    const options = {
      operation: "getOrderCountsForFilterOptions",
      tags: ["order"],
      filters: [
        // todays order information
        {
          dateRange: [
            moment(dateRange.from).startOf("day").toDate(),
            moment(dateRange.to).endOf("day").toDate(),
          ],
          countTypes,
          groupingFieldName,
          // = [
          //   "totalScheduledOrders",
          //   "totalPunchedOrders",
          //   "completedOrdersSitDr",
          //   "outForServiceOrdersSitDr",
          //   "todayDelayedOrders",
          //   "remainingOrdersSitDr",
          // ],
          // hubId: { $in: [states.hub] }, will be added
        },
      ],
    };

    // setting up the filers based on universal filters
    if (city === "others") {
      const hubs = [];
      hubsByCity.forEach((city) => {
        city.hubs.forEach((hub) => {
          hubs.push(hub._id);
        });
      });

      options.filters[0].hubId = {
        $nin: hubs,
      };
    } else if (city !== "all") {
      if (hub === "all") {
        options.filters[0].hubId = {
          $in: hubsByCity
            ?.find((obj) => obj.city === city)
            ?.hubs.map((hub) => hub._id),
        };
      } else {
        options.filters[0].hubId = { $in: [hub] };
      }
    }

    const res = await v2Api(options);
    setData(res?.countArray?.at(0) || null);

    setIsLoading(false);
  }, [
    dateRange.from,
    dateRange.to,
    countTypes,
    groupingFieldName,
    city,
    hubsByCity,
    hub,
  ]);

  useEffect(() => {
    onInitailLoad();
  }, [onInitailLoad]);

  useEffect(() => {
    setCity(universalFilter.city);
    setHub(universalFilter.hub);
  }, [universalFilter.city, universalFilter.hub]);

  return {
    data,
    isLoading,
    dateRange,
    setDateRange,
    setCity,
    setHub,
    city,
    hub,
    refetch: onInitailLoad,
    groupingFieldName,
    setGroupingFieldName,
  };
};

export default useOrderCountData;
