import { Link } from "react-router-dom";

const BodyComp = ({ item, index }) => {
  const Component = item?.to ? Link : "div";
  return (
    <Component
      to={item?.to}
      key={index}
      className={`border-b border-gray-300  text-gray-600 py-2 px-3  flex justify-between text-2xs md:text-xs`}
    >
      <p className="whitespace-nowrap first-letter:capitalize">
        {item?.title || "Hub"}
      </p>
      <p className="whitespace-nowrap">{item?.count || 0}</p>
    </Component>
  );
};

export default BodyComp;
