import React, { useState } from "react";
import { MdEdit, MdCheck, MdClose } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { Switch } from "@mui/material";

// Debounce function to limit API calls
let timeoutId;

export default function BillSection({
  states,
  setStates,
  orderStates,
  setOrderStates,
  orderTotal,
}) {
  return (
    <div className="flex-col w-full mt-2 space-y-4">
      {/* Coupon Selection */}
      <div className="w-full bg-white p-4 border rounded-md  shadow-sm">
        <CouponSelector
          orderStates={orderStates}
          setOrderStates={setOrderStates}
        />
      </div>
      {/* Bill Summary */}
      <BillSummary {...{ orderStates, orderTotal, setOrderStates }} />

      <div className=" w-full bg-white items-center  justify-between flex gap-2 text-sm  rounded-lg p-4 border border-gray-200 ">
        <div className="  font-[500]   w-full ">
          Prepaid Discount from Site?
        </div>
        <Switch
          name="active"
          checked={orderStates?.prepaidDiscountAvailable}
          onChange={(event) =>
            setOrderStates((p) => ({
              ...p,
              prepaidDiscountAvailable: event.target.checked,
            }))
          }
        />
      </div>
    </div>
  );
}

const BillSummary = ({ orderStates, orderTotal, setOrderStates }) => {
  const [editingField, setEditingField] = useState(null);
  const [inputValue, setInputValue] = useState("");

  // Handle edit save
  const handleSaveEdit = () => {
    if (editingField) {
      setOrderStates((prev) => ({
        ...prev,
        [editingField]: parseInt(inputValue) || 0,
      }));
      setEditingField(null);
      setInputValue("");
    }
  };

  // Handle cancel edit
  const handleCancelEdit = () => {
    setEditingField(null);
    setInputValue("");
  };

  // Start editing a field
  const startEditing = (field, value) => {
    setEditingField(field);
    setInputValue(value.toString());
  };

  return (
    <div className="w-full mt-2 bg-white rounded-lg p-4 border flex flex-col gap-3">
      <div className="font-bold text-lg w-full border-b pb-2 text-gray-700">
        Bill Summary
      </div>

      {/* Decor Total */}
      <div className="flex justify-between w-full text-sm">
        <span>Decor Total</span>
        <span className="font-semibold">₹{orderTotal.decor}</span>
      </div>

      {/* Addons Total */}
      <div className="flex justify-between w-full text-sm">
        <span>Addons Total</span>
        <span className="font-semibold">₹{orderTotal.addonsTotal}</span>
      </div>

      {/* Coupon Discount */}
      <div className="flex justify-between w-full text-sm">
        <span>Applied Coupon</span>
        <span className="font-semibold text-red-600">
          - ₹{orderTotal.coupanDiscount}
        </span>
      </div>

      {/* Editable Prepaid Discount */}
      <div className="flex items-center justify-between w-full text-sm">
        <div className="flex items-center gap-1">
          Prepaid Discount
          {editingField === "prepaidDiscount" ? (
            <>
              <MdCheck
                className="text-green-500 cursor-pointer"
                onClick={handleSaveEdit}
              />
              <MdClose
                className="text-red-500 cursor-pointer"
                onClick={handleCancelEdit}
              />
            </>
          ) : (
            <MdEdit
              className="text-blue-500 cursor-pointer"
              onClick={() =>
                startEditing(
                  "prepaidDiscount",
                  orderStates.prepaidDiscount || 0
                )
              }
            />
          )}
        </div>
        {editingField === "prepaidDiscount" ? (
          <input
            type="number"
            className="border border-gray-300 rounded-md p-1 w-20 text-right"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        ) : (
          <span className="font-semibold text-red-600">
            - ₹{orderStates.prepaidDiscount || 0}
          </span>
        )}
      </div>

      {/* Editable Convenience Fee */}
      <div className="flex items-center justify-between w-full text-sm">
        <div className="flex items-center gap-1">
          Convenience Fee
          {editingField === "convenienceFee" ? (
            <>
              <MdCheck
                className="text-green-500 cursor-pointer"
                onClick={handleSaveEdit}
              />
              <MdClose
                className="text-red-500 cursor-pointer"
                onClick={handleCancelEdit}
              />
            </>
          ) : (
            <MdEdit
              className="text-blue-500 cursor-pointer"
              onClick={() =>
                startEditing("convenienceFee", orderStates.convenienceFee || 0)
              }
            />
          )}
        </div>
        {editingField === "convenienceFee" ? (
          <input
            type="number"
            className="border border-gray-300 rounded-md p-1 w-20 text-right"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        ) : (
          <span className="font-semibold text-green-600">
            + ₹{orderStates.convenienceFee || 0}
          </span>
        )}
      </div>

      {/* Total Amount */}
      <div className="flex justify-between border-t pt-3 w-full font-semibold text-md">
        <span>Total</span>
        <span className="text-gray-800">
          ₹
          {orderTotal.total -
            (parseInt(orderStates.prepaidDiscount) || 0) +
            (parseInt(orderStates.convenienceFee) || 0) -
            (parseInt(orderTotal.coupanDiscount) || 0)}
        </span>
      </div>
    </div>
  );
};

export function CouponSelector({ orderStates, setOrderStates }) {
  const [searchText, setSearchText] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);

  const debounce = (func, delay = 800) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Fetch coupons based on searchText using v2Crud
  const fetchCoupons = (query) => {
    window.v2Crud({
      queries: [
        {
          modelName: "Offer",
          operation: "read",
          filter: {
            $or: [
              { offerName: { $regex: query, $options: "i" } },
              { offerCode: { $regex: query, $options: "i" } },
            ],
            active: true,
            visibility: true,
          },
          limit: 5,
          sort: { createdAt: -1 },
        },
      ],
      onInitiate: () => setLoading(true),
      onSuccess: (res) => {
        setCoupons(res?.data?.at(0)?.data || []);
        setLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching coupons:", error);
        setLoading(false);
      },
    });
  };

  const debouncedFetchCoupons = debounce(fetchCoupons);

  // Handle search input change with debouncing
  const handleSearchChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    setLoading(true);
    debouncedFetchCoupons(text);
  };

  // Select a coupon and apply it to the order
  const handleCouponSelect = (coupon) => {
    setOrderStates((prev) => ({
      ...prev,
      coupan: coupon,
    }));
    setSearchText("");
  };

  // Remove the applied coupon
  const handleRemoveCoupon = () => {
    setOrderStates((prev) => ({
      ...prev,
      coupan: null,
    }));
    setSearchText("");
    setCoupons([]);
  };

  return (
    <div className="w-full">
      {/* Coupon Search Input */}
      <div className="flex flex-col">
        <label className="font-medium text-sm text-gray-700 mb-1">
          {orderStates.coupan ? "Selected Coupon" : "Search and Apply Coupon"}
        </label>

        {/* Show the applied coupon or search input */}
        {orderStates.coupan ? (
          <div className="flex items-center justify-between bg-gray-100 p-2 rounded-md">
            <span className="text-[#2136d4]  text-xs font-semibold">
              {orderStates.coupan.offerCode} - {orderStates.coupan.offerName}
            </span>
            <AiFillDelete
              onClick={handleRemoveCoupon}
              className="text-red-600 cursor-pointer text-sm"
            />
          </div>
        ) : (
          <input
            type="text"
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search by Coupon Code or Name"
            className="p-2 border border-gray-300 text-xs rounded-md"
          />
        )}
      </div>
      <div className="w-full flex-col space-y-4 max-h-[11rem] overflow-scroll">
        {loading
          ? Array(5)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className=" w-full h-[3rem]  flex-shrink-0 rounded-lg skeleton"
                ></div>
              ))
          : searchText &&
            coupons.length > 0 && (
              <ul className="space-y-2">
                {coupons.map((coupon) => (
                  <li
                    key={coupon._id}
                    onClick={() => handleCouponSelect(coupon)}
                    className="p-2 cursor-pointer hover:bg-blue-100 rounded-md border flex justify-between"
                  >
                    <span className="font-semibold  text-[#2136d4]">
                      {coupon.offerCode}
                    </span>
                    <span className="text-gray-500 text-xs">
                      {coupon.offerName}
                    </span>
                  </li>
                ))}
              </ul>
            )}
      </div>
      {/* Coupon List or Loading State */}

      {/* No coupons found message */}
      {searchText && !loading && coupons.length === 0 && (
        <div className="text-center text-gray-500 mt-2">No coupons found</div>
      )}
    </div>
  );
}
