import moment from "moment";
import { get_orders_by_query_obj, placeOrder } from "../../../../api/orderAPI";

const handelPlaceOrder = async (orderData, orderTotal, adminInfo) => {
  try {
    // calculations
    const webDiscount = Math.round(
      (orderData.product.costPrice * parseInt(orderData.product.webDiscount)) /
        100
    );

    // addon
    const addonPrice = orderTotal.addonsTotal;
    const couponSaving = orderTotal.coupanDiscount;

    // convenienceFee
    const convenienceFee = parseInt(orderData.convenienceFee || 0);

    // final price
    const finalPrice =
      parseInt(orderData.product.sellingPrice) +
      addonPrice -
      parseInt(couponSaving) +
      convenienceFee -
      parseInt(orderData.prepaidDiscount || 0);

    const userId = orderData.user._id;

    const priceDetail = {
      costPrice: orderData.product.costPrice,
      addonPrice: addonPrice,
      webDiscount: webDiscount,
      couponSaving: couponSaving,
      convenienceFee: convenienceFee,
      prepaidDiscount: orderData.prepaidDiscount || 0,
      deliveryPrice: false,
      finalPrice: finalPrice,
      pendingAmount: finalPrice,
      paymentStatus: "pending",
      tax: false,
    };

    const offers = orderData.coupan?._id
      ? [{ offer: orderData.coupan._id }]
      : [];

    const orderDate = moment().toISOString();
    const bookingDetails = [
      {
        status: "Order Placed",
        date: orderDate,
      },
    ];

    const date = {
      orderDate,
      delivery: {
        readyTime: orderData.readyTime,
        arrivingTime: orderData.arrivingTime,
        celebrationDate: orderData.celebrationDate,
        workHour: orderData.workHour,
      },
    };

    const bookingId = await bookingIdCreate(
      userId,
      orderData.celebrationDate || moment().add(2, "days").toISOString()
    );

    for (const eachImg of orderData.decorImages) {
      orderData.product.images.push(eachImg);
    }

    const newAddOns = [];
    orderData.addOns.forEach((curr, idx) => {
      if (curr.addonId._id === "customAddon") {
        newAddOns.push({
          addonId: {
            ...curr.addonId,
          },
          quantity: curr.quantity,
          color: curr.color,
          text: curr.text,
        });
      } else {
        newAddOns.push({
          addonId: curr.addonId,
          quantity: curr.quantity,
          color: curr.color,
          text: curr.text,
        });
      }
    });

    const data = {
      userId,
      productId: {
        ...orderData.product,
        _id: orderData?.product?._id || "custom",
      },
      priceDetail,
      orderType: "Upcoming",
      offers,
      date,
      orderServiceStatus: [
        {
          bookingStatus: "Order Placed",
          serviceStatus: [
            { status: "pendingToApprove", date: moment().toISOString() },
          ],
        },
      ],
      paymentStatus: "COD",
      addOns: newAddOns,
      freebie: orderData.freebie,
      bookingStatus: "Order Placed",
      bookingDetails,
      addressId: orderData.bookingAddress,
      bookingId,
      hubId: orderData?.hubId || "",
      paymentDetails: [],
      prepaidDiscountAvailable: orderData.prepaidDiscountAvailable,
      tags: ["placed by agent"],
      assignedAgents: [
        {
          admin: adminInfo?._id,
          createdAt: moment().toISOString(),
          tags: [],
          remark: `Order Placed by ${adminInfo?.name} - ${adminInfo?.mobile}`,
        },
      ],
    };

    const res = await placeOrder(data);
    if (res.isSuccess) {
      sessionStorage.removeItem("MOP_orderData"); // Clear local storage after placing order

      return {
        isSuccess: true,
        bookingId: data.bookingId,
        celebrationDate: data.date.delivery.date,
      };
    } else {
      window.toast(res.message);
      return {
        isSuccess: false,
        bookingId: data.bookingId,
        celebrationDate: data.date.delivery.date,
      };
    }
  } catch (error) {
    console.log(error);
    window.toast(error.message, "error");
  }
};

export const calculate_addons_price = (addOns) => {
  if (!addOns) return 0;
  const prices = addOns.map((addon) => {
    if (addon.text !== "" || addon.text.length !== 0) {
      const trimmedText = addon.text.split(" ").join("");
      return addon.addonId.discountedPrice * trimmedText.length;
    }
    return addon.addonId.discountedPrice * addon.quantity;
  });
  const totalPrice = prices.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return parseInt(totalPrice);
};

const calculate_offer_discount = (offer, cartPrice) => {
  if (!offer) return 0;
  if (offer.minOrderValue <= cartPrice) {
    if (offer?.discountType === 0) {
      // 0 : percentage , 1 : amount
      const discount = Math.round((cartPrice * offer?.discountValue) / 100);
      if (discount > offer?.maxOfferAmountGain) {
        return offer?.maxOfferAmountGain;
      } else {
        return discount;
      }
    } else {
      return offer?.discountValue;
    }
  } else if (
    offer.isRange2 &&
    offer.range2MinOrderValue <= cartPrice &&
    offer.range2MaxOrderValue >= cartPrice
  ) {
    if (offer?.range2DiscountType === 0) {
      // 0 : percentage , 1 : amount
      const discount = Math.round(
        (cartPrice * offer?.range2DiscountValue) / 100
      );
      if (discount > offer?.range2MaxPriceGain) {
        return offer?.range2MaxPriceGain;
      } else {
        return discount;
      }
    } else {
      return offer?.discountValue;
    }
  } else {
    return 0;
  }
};

const calculateCostPrice = (sellingPrice, offer) => {
  if (!offer) return sellingPrice; // No offer, costPrice is equal to sellingPrice

  const tryCalculate = (range) => {
    if (range.discountType === 0) {
      // Percentage discount
      let assumedCostPrice = Math.round(
        sellingPrice / (1 - range.discountValue / 100)
      );
      let discount = Math.round((assumedCostPrice * range.discountValue) / 100);

      if (discount > range.maxOfferAmountGain) {
        discount = range.maxOfferAmountGain;
        assumedCostPrice = sellingPrice + discount;
      }

      const actulaCalculatedDiscount = calculate_offer_discount(
        offer,
        assumedCostPrice
      );

      if (actulaCalculatedDiscount === discount) {
        return assumedCostPrice;
      }
    } else {
      // Fixed amount discount
      const assumedCostPrice = sellingPrice + range.discountValue;
      if (assumedCostPrice >= range.minOrderValue) {
        return assumedCostPrice;
      }
    }
    return null; // Doesn't satisfy range conditions
  };

  // Check primary range
  let costPrice = tryCalculate({
    discountType: offer.discountType,
    discountValue: offer.discountValue,
    maxOfferAmountGain: offer.maxOfferAmountGain,
    minOrderValue: offer.minOrderValue,
  });
  if (costPrice) return costPrice;

  // Check range 2
  if (offer.isRange2) {
    costPrice = tryCalculate({
      discountType: offer.range2DiscountType,
      discountValue: offer.range2DiscountValue,
      maxOfferAmountGain: offer.range2MaxPriceGain,
      minOrderValue: offer.range2MinOrderValue,
    });
    if (costPrice) return costPrice;
  }

  // Default to sellingPrice
  return sellingPrice;
};

const bookingIdCreate = async (userId) => {
  const res = await get_orders_by_query_obj({
    queryObject: { userId: userId },
    skip: 0,
    limit: 1000,
    sortingObj: { createdAt: "desc" },
    projectionString: "_id createdAt",
  });
  const orders = res.orders;

  const lastOrderIndex =
    orders.length > 0 ? parseInt(orders[0]._id?.split(userId)[1]) || 0 : 0;

  // if the booking id becomes same we will send a email notification with all the booking details in future versions

  const bookingId = "E" + userId + (lastOrderIndex + 1);

  return bookingId;
};

export { handelPlaceOrder, calculate_offer_discount, calculateCostPrice };
