import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { v4 as uuidv4 } from "uuid";
import { s3RemoveApi, s3UploadApi } from "../../../../api/aws/s3";
import { useStoreContext } from "../../../../context/StoreContext";
import { CouponSelector } from "./BillSection";
import {
  calculate_offer_discount,
  calculateCostPrice,
} from "./placeManualOrder";

export default function ProductForm({
  formData,
  handleInput,
  handleSubmitbtn,
  productSpinner,
  decorImages,
  setDecorImages,
  setFormData,
  mainFormData,
  setMainFormData,
}) {
  const {
    storeStates: { decorCategoryList },
  } = useStoreContext();

  const [customerPriceAnalysis, setCustomerPriceAnalysis] = useState({
    productPrice: mainFormData.product?.sellingPrice || 0,
    finalCustomerPrice: 0,
    discountedAmount: 0,
    prepaidDiscount: 0,
  });

  useEffect(() => {
    if (
      mainFormData.coupan &&
      (customerPriceAnalysis.productPrice ||
        customerPriceAnalysis.finalCustomerPrice)
    ) {
      handlePriceAnalysisChange({
        target: {
          name: "productPrice",
          value: customerPriceAnalysis.productPrice,
        },
      });
    }

    // eslint-disable-next-line
  }, [mainFormData.coupan]);

  const handlePriceAnalysisChange = (e) => {
    let { name, value } = e.target;

    let productPrice = 0;
    let finalCustomerPrice = 0;
    let prepaidDiscount = 0;
    let coupanDiscount = 0;

    // convert string to number
    if (name === "productPrice") {
      productPrice = Number(value);
      prepaidDiscount = Number(customerPriceAnalysis.prepaidDiscount);
      coupanDiscount = calculate_offer_discount(
        mainFormData.coupan,
        productPrice
      );
      finalCustomerPrice = productPrice - coupanDiscount - prepaidDiscount;
    } else if (name === "finalCustomerPrice") {
      finalCustomerPrice = Number(value);
      prepaidDiscount = Number(customerPriceAnalysis.prepaidDiscount);
      productPrice = calculateCostPrice(
        finalCustomerPrice,
        mainFormData.coupan
      );
      coupanDiscount = productPrice - finalCustomerPrice;
    } else if (name === "prepaidDiscount") {
      prepaidDiscount = Number(value);
      productPrice = customerPriceAnalysis.productPrice;
      coupanDiscount = calculate_offer_discount(
        mainFormData.coupan,
        productPrice
      );
      finalCustomerPrice = productPrice - coupanDiscount - prepaidDiscount;
    }
    console.log("productPrice", productPrice);
    setCustomerPriceAnalysis({
      ...customerPriceAnalysis,
      productPrice,
      finalCustomerPrice,
      prepaidDiscount,
      discountedAmount: coupanDiscount,
    });
  };

  const handleImageChange = async (event) => {
    try {
      const files = event.target.files;
      const newDecorImagesArray = [...decorImages];
      const imagesToUpload = [];

      window.loadingScreen(true, "Uploading images...");
      for (const file of files) {
        const idx = uuidv4();
        imagesToUpload.push({
          file: file,
          path: "orders/decors/" + idx + ".webp",
        });
        newDecorImagesArray.push(
          "https://img.ebonow.com/custom/orders/decors/" + idx + ".webp"
        );
      }
      if (imagesToUpload.length > 0) {
        await s3UploadApi(imagesToUpload);
        setDecorImages(newDecorImagesArray);
      }
      window.loadingScreen(false);
    } catch (error) {
      window.loadingScreen(false);
      console.error("Error uploading image:", error);
    }
  };

  const handleRemoveImage = async (imageInfo) => {
    try {
      window.loadingScreen(true, "Removing image...");
      if (imageInfo.includes("https://img.ebonow.com/custom")) {
        const imagePath = imageInfo.split("https://img.ebonow.com/custom/");
        const newDecorImagesArray = decorImages.filter(
          (curr) => curr !== imageInfo
        );
        setDecorImages(newDecorImagesArray);
        await s3RemoveApi([imagePath[1]]);
      }
      window.loadingScreen(false);
    } catch (error) {
      window.loadingScreen(false);
      console.error("Error removing image:", error);
    }
  };

  return (
    <form onSubmit={handleSubmitbtn} className="flex-col space-y-6">
      {/* Product Name */}
      <label className="flex flex-col">
        <span className="font-medium mb-1">Product Name</span>
        <input
          type="text"
          placeholder="Enter Product Name"
          value={formData.name}
          onChange={handleInput}
          name="name"
          className="p-2 border border-gray-300 rounded-md"
        />
      </label>

      {/* Category and Price */}
      <div className="grid grid-cols-2 gap-4">
        <label className="flex flex-col">
          <span className="font-medium mb-1">Category</span>
          <select
            name="ocassionCategory"
            value={formData.ocassionCategory}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                ocassionCategory: e.target.value,
              }))
            }
            className="p-2 border border-gray-300 rounded-md"
          >
            <option value="">Select Category</option>
            {decorCategoryList.map((category) => (
              <option key={category.code} value={category.code}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </label>

        <label className="flex flex-col">
          <span className="font-medium mb-1">Customer Price</span>
          <input
            type="number"
            placeholder="Enter Customer Price"
            value={formData.sellingPrice}
            onChange={handleInput}
            name="sellingPrice"
            className="p-2 border border-gray-300 rounded-md"
          />
        </label>
      </div>

      {/* Image Upload */}
      <div className="flex flex-col items-start">
        <span className="font-medium mb-1">Upload Images</span>
        <input
          type="file"
          accept="image/jpeg, image/png, image/webp"
          multiple={true}
          onChange={handleImageChange}
          className="mb-4 "
        />
        <div className="flex gap-3 flex-wrap">
          {decorImages.map((curr, idx) => (
            <div
              key={idx}
              className="relative rounded-md overflow-hidden h-24 w-24 border shadow-sm"
            >
              <RxCross2
                onClick={() => handleRemoveImage(curr)}
                className="absolute top-1 right-1 cursor-pointer text-white bg-red-600 rounded-full p-1 shadow"
              />
              <img
                src={curr}
                alt="decor"
                className="w-full h-full object-cover rounded-md"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="p-2 w-full bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center justify-center"
      >
        {productSpinner ? "Saving..." : "Save"}
      </button>

      <div className="p-4 mt-4 border border-gray-300 rounded-md bg-gray-50">
        <h3 className="font-semibold text-sm mb-3 text-center">
          Customer Price Analysis
        </h3>

        {/* Coupon Selector */}
        <CouponSelector
          orderStates={mainFormData}
          setOrderStates={setMainFormData}
        />

        {/* Calculation Breakdown */}
        <div className="grid grid-cols-2 gap-4 mt-4 text-sm">
          <div className="flex flex-col">
            <span className="text-xs font-medium text-gray-500">
              Base Product Price (₹)
            </span>
            <input
              type="number"
              name="productPrice"
              value={customerPriceAnalysis.productPrice}
              onChange={handlePriceAnalysisChange}
              className="p-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="flex flex-col">
            <span className="text-xs font-medium text-gray-500">
              Coupon Discount (₹)
            </span>
            <div className="p-2 border border-gray-300 rounded-md bg-green-100 text-green-800 font-semibold">
              - ₹{customerPriceAnalysis.discountedAmount?.toFixed?.(2)}
            </div>
          </div>

          <div className="flex flex-col">
            <span className="text-xs font-medium text-gray-500">
              Prepaid Discount (₹)
            </span>
            <input
              type="number"
              name="prepaidDiscount"
              value={customerPriceAnalysis.prepaidDiscount}
              onChange={handlePriceAnalysisChange}
              className="p-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="flex flex-col">
            <span className="text-xs font-medium text-gray-500">
              Final Customer Price (₹)
            </span>
            <input
              type="number"
              name="finalCustomerPrice"
              value={customerPriceAnalysis.finalCustomerPrice}
              onChange={handlePriceAnalysisChange}
              className="p-2 border border-gray-300 rounded-md font-semibold text-blue-800"
            />
          </div>
        </div>

        {/* Visual Breakdown */}
        <div className="mt-4 p-3 border border-gray-200 rounded-md bg-gray-100">
          <h4 className="text-xs font-medium text-gray-500 mb-2">Breakdown:</h4>
          <div className="flex flex-col gap-1 text-xs">
            <div className="flex justify-between">
              <span>Base Product Price:</span>
              <span>₹{customerPriceAnalysis.productPrice.toFixed?.(2)}</span>
            </div>
            <div className="flex justify-between text-green-600">
              <span> - Coupon Discount:</span>
              <span>
                ₹{customerPriceAnalysis.discountedAmount?.toFixed?.(2)}
              </span>
            </div>
            <div className="flex justify-between text-green-600">
              <span> - Prepaid Discount:</span>
              <span>₹{customerPriceAnalysis.prepaidDiscount}</span>
            </div>
            <div className="flex justify-between border-t border-gray-300 pt-2 font-medium">
              <span>Final Customer Price:</span>
              <span className="text-blue-600">
                ₹{customerPriceAnalysis.finalCustomerPrice.toFixed?.(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
